<template>
  <b-alert :show="true"
           class="pt-30 mt-30 pl-30 pr-48 d-flex justify-content-between"
           variant="alert alert-card alert-success">
    <div>
      <h3>Payment Confirmed close this</h3>

    </div>
    <i class="text-50 text-success i-Yes"></i>
  </b-alert>
</template>

<script>
export default {
  name: 'PaymentComplete',
  mounted () {
    console.log('mounted')
    parent.c_1.completePayment()
  }
}
</script>
